@import "styles/styles";

.layoutContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    position: absolute;
    width: 100%;
}
.mainWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: $system-fonts;
    height: 100%;
    justify-content: center;
    width: 100%;
    padding-top: 0;
    @include media-breakpoint-up(md) {
        padding: 80px 0 32px;
    }
    .backgroundImage {
        background-color: rgb(235, 225, 255);
        background-image: url($S3_BUCKET + "/landing_pages/img/confetti_large.svg");
        background-repeat: repeat-y;
        background-size: 100%;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }
    .headerClass {
        background-color: $landing-violet-color;
        font-family: $system-fonts;
        top: 0;
    }
    .modalTopBorder {
        position: absolute;
        width: 100%;
        z-index: 1;
        background-color: $landing-violet-color;
        height: 0px;
        border-radius: 0;

        @include media-breakpoint-up(md) {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            height: 6px;
        }
    }
    .banner {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: center;
        padding-bottom: 33.33%;
        padding-bottom: calc(100% / 3);

        h4 {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -84px;
        }

        .bannerAvatar {
            position: absolute;
            z-index: 999;
            border-radius: 50%;
            margin: 0 auto;
            width: 80px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -48px;

            img {
                width: 100%;
                height: auto;
            }
            @include media-breakpoint-up(md) {
                border: 3px solid #fff;
                height: 80px;
                width: 80px;
            }
            @include media-breakpoint-down(sm) {
                border: 2px solid #fff;
                height: 64px;
                width: 64px;
            }
        }
        @include media-breakpoint-down(sm) {
            border-radius: 0;
        }
    }

    .mainContentArea {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #fff;
        position: relative;
        border-radius: 0;
        margin-top: 50px;
        @include media-breakpoint-up(md) {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
        .mainContent {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            h1 {
                margin: 0;
                font-size: 18px;
                color: #444;
                line-height: 1.5;
                text-transform: none;
            }
            p {
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                padding: 20px 40px;
            }
            a {
                text-decoration: none;
                font-size: 18px;
                color: #444;
                font-weight: 600;
            }
        }
    }
}
