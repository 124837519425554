@import 'piconetworks/pkg-storybook-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);

.avatar {
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    border-radius: 50%;
    max-width: initial;
  }
}
