@import 'piconetworks/pkg-storybook-styles/scss';

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    $result: ();

    @if (length($shadow) == 1) {
      // We can pass `@include box-shadow(none);`
      $result: $shadow;
    } @else {
      // Filter to avoid invalid properties for example `box-shadow: none, 1px 1px black;`
      @for $i from 1 through length($shadow) {
        @if nth($shadow, $i) != "none" {
          $result: append($result, nth($shadow, $i), "comma");
        }
      }
    }
    @if (length($result) > 0) {
      box-shadow: $result;
    }
  }
}

.header {
  color: $white;
  min-height: 3rem;
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  z-index: 3;
  //flex
  display: flex;
  align-items: center;
  justify-content: space-between;
  //flex

  //shadow
  @include box-shadow(0 2px 12px rgba(0,0,0,.12),0 4px 48px rgba(0,0,0,.24));
  //shadow

  h4 {
      margin: 0;
  }
}
