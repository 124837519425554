@import 'piconetworks/pkg-storybook-styles/scss';

.row {
  @include make-row();
}

.displayInlineFlex {
  display: inline-flex;
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
