//common color variables from Trypico project
$color-white: #fff;
$color-black: #000;

//landing page colors
$landing-dark-text-color: #444;
$landing-gray-color: #aaa;
$landing-gray-color2: #777;
$landing-gray-color3: #7b7b7b;
$landing-xlight-gray-color: #fafafa;
$landing-warm-gray-color: #9a9a9a;
$landing-light-gray-color: #f4f4f4;
$landing-lighter-gray-color: #ddd;
$landing-violet-color: #7a34ff;
$landing-violet-color-2: #8748ff;
$landing-smooth-blue-color: #4285f4;
$landing-light-blue-color: #caaeff;
$landing-light-red-color: #ff5e5b;
$landing-dark-gray-color: #777777;
$landing-silver-color: #dddddd;
$landing-dark-blue-color: #004f80;
$landing-disabled-gray: #eeeeee;
$landing-primary-disabled: #c9adff;
$landing-secondary-disabled: #f8f8f8;
$landing-secondary-disabled-text: #cccccc;
$landing-disabled-blue: #a0c2fa;
$landing-hover-button-blue: #699ef7;
$landing-facebook-blue-color: #1877f2;
$landing-google-blue-color: #4285f4;
$landing-google-red-color: #c53929;
$landing-pantone-color: #00c590;
$linkedin-background-color: #0a66c2;
$twitter-background-color: #1da1f2;
$instagram-background-color: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
$black-background-color: #000000;
$error-background: #ffefee;
$twitch-background-color: #9146ff;
