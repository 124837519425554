@import 'piconetworks/pkg-storybook-styles/scss';

.socialLink {
    display: inline-block;
    height: 20px;
    margin: 0.25rem 0.5rem;
    position: relative;
    width: 20px;

    svg,
    .noSvg {
        height: 20px !important;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px !important;
        path {
            fill: var(--page-contrast-color);
        }
    }

    .noSvg {
        background: $gray-500;
        border-radius: 50%;
    }
}
