@import 'piconetworks/pkg-storybook-styles/scss';

.wizardArrow {
  padding: 0.675rem !important;
  flex: 0 !important;

  svg {
    color: var(--linkColor);
  }
  
  &.done{
    background-color: $green;
    .icon {
        color: $white;
    }
  }
}
