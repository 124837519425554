@import 'piconetworks/pkg-storybook-styles/scss';

.whiteBackground {
  display: flex;
  background-color: $white;
  border-radius: 50%;
  svg {
    flex-basis: auto;
    height: auto;
    padding: 5px;
  }
  .svg:before {
    content: '';
    padding-top: 100%;
  }
}
