@import 'piconetworks/pkg-storybook-styles/scss';

.PicoLoaderContainer_modal {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 0px;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
@media only screen and (min-width: 768px) {
  .PicoLoaderContainer_modal {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .PicoLoaderContainer_modal {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
  }
}
.PicoLoader_modal {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  border-radius: 50%;
  height: 40px;
  margin: 4px auto;
  width: 40px;
}
.PicoLoader_modal {
  border: 4px solid #aaa;
  border-right-color: transparent;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  top: 50%;
}
@-webkit-keyframes rotate-forever {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
