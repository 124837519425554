@import 'piconetworks/pkg-storybook-styles/scss';

.modalMarginPreset {
  margin: 2rem;
  width: auto;
}

// for settings forms
.defaultMarginPreset {
  margin-top: 2.5rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  margin-left: 3rem;
  width: auto;
}

// for login spacing
.spaciousMarginPreset {
  margin: 3.75rem;
  width: auto;
}

// for login spacing
.narrowMarginPreset {
  margin: 1rem;
  width: auto;
}