@import 'piconetworks/pkg-storybook-styles/scss';

.flexboxPaneContainer {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: $navbar-light-bg-color;
  max-width: 1104px; // 1140 - 1.125rem - 1.125rem (margin)
  margin: 3rem 1.125rem;
  padding: 0;
}
.flexboxPaneContainer:last-child {
  margin-bottom: 7.5rem;
}

.flexboxPaneChild {
  padding: 2.5rem 1.5rem;
}
