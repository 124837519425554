@import 'piconetworks/pkg-storybook-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);
$rgbColorValue: var(--rgbColorValue);

.otpContainer {
  div {
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
  }
  input {
    height: 3rem;
    border-radius: 8px;
    border: 1px solid $gray-500;
    box-sizing: border-box;

    color: $black;
    font-size: 1rem;
    font-weight: normal;
    line-height: 20px;

    &:active,
    &:focus,
    &:focus-within {
      border: 1px solid $color;
      box-shadow: 0px 0px 0px 4px rgba($rgbColorValue, 0.2);
      outline: none;
    }

    &::placeholder {
      color: $gray-500;
      font-weight: normal;
      width: auto;
      height: 1.25rem;
      line-height: 1.25rem;
    }
  }
  .disabledStyle {
    border: 1px solid $gray-500;
    background-color: $gray-300;
    color: $gray-700;
  }
  .errorStyle {
    border: 1px solid #ff4440;
    background-color: $soft-danger;
    color: $red;
    &:focus {
      border: 1px solid #ff4440;
    }
    &::placeholder {
      color: #ffb4b2;
    }
  }
}
.danger {
  display: inline-block;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  color: #ff4440;
}
