@import 'piconetworks/pkg-picore-styles/scss';

.wizard {
    .wizardHeader {
        background-color: $color-white;
        padding: 1.875rem;
        position: relative;
        margin-bottom: 1rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: -6px;

        &.wizardHeaderTopPadding {
            padding-top: 3.5rem;
        }

        .backButton {
            position: absolute;
            left: 1.875rem;
            border-radius: 0.5rem;
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
            min-height: 42px;

            &.noTitle {
                top: 0.5rem;
            }
            
            & + h3{
                @include media-breakpoint-down(md) {
                    padding-left: 3rem;
                }
            }
        }

        h3 {
            font-size: 1.25rem;
            margin: 0 1rem;
            font-weight: bold;
            min-height: 42px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            small {
                font-size: 1rem;
                letter-spacing: 0.1px;
                display: block;
                margin-top: 0.25rem;
                font-weight: normal;
            }
        }
    }
    .wizardContentWrapper {
        position: relative;
        @include media-breakpoint-down(md) {
            padding-bottom: 0px;
        }
        &.margin {
            margin-top: 1rem;
        }
        .description {
            color: $black;
            font-size: 0.875rem;
            padding: 1.5rem 3rem;
            line-height: 1.5;
        }
        .wizardContent {
            margin: 0 auto;
            padding: 0 1rem 2rem;
            width: 100%;
            $color: var(--linkColor);

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            .description {
                color: $black;
                font-size: 0.875rem;
                padding: 1.5rem 0;
                line-height: 1.5;
            }
            input,
            textarea {
                border-width: 1px;
                font-size: 1rem;
                line-height: 16px;
                min-width: 240px;
                &::placeholder {
                    color: $gray-500;
                }

                @include media-breakpoint-down(md) {
                    min-width: 200px;
                }
            }
            input {
                height: 44px;
                min-height: 44px;
                border-radius: 0.5rem;

                &:focus{
                    border-color: $color !important;
                }
            }
        }
    }
}
