@import 'piconetworks/pkg-storybook-styles/scss';

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  div {
    margin-bottom: 0;
  }
  &::before {
      display: inline-flex;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%23120A20' fill-rule='evenodd'/%3E%3C/svg%3E");
      position: absolute;
      left: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      padding: 1rem;
  }
  &.searching {
    &::after {
      display: inline-flex;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='currentcolor' fill-rule='evenodd' d='M12,0 C18.627417,0 24,5.372583 24,12 C24,14.258231 23.3762203,16.3707704 22.2915649,18.1747141 L20.5763803,17.1454684 C21.4802128,15.6422098 22,13.8818063 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,13.8822261 2.52001916,15.6429951 3.42422463,17.1464744 L1.70903973,18.1757196 C0.624011557,16.3715554 0,14.2586506 0,12 C0,5.372583 5.372583,0 12,0 Z'/%3E%3C/svg%3E%0A");
      position: absolute;
      width: $spinner-width-sm;
      height: $spinner-height-sm;
      animation: spinner-border $spinner-animation-speed linear infinite;
      right: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      padding: 1rem;
    }
  }
}
.isDirty {
  &::after {
    display: inline-flex;
    position: absolute;
    right: 1rem;
  }
}

.pseudoInline {
  position: absolute;
  right: 12px;
  height: 28px;
}
