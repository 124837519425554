@import 'piconetworks/pkg-storybook-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);

// html {
//  :global {
    @import "~bootstrap/scss/buttons";
    .btn {
      align-items: center;
      border-radius: $btn-border-radius;
      border: $btn-border-width solid rgba($black, 0.05);
      box-shadow: $secondary-btn-box-shadow;
      cursor: default !important;
      display: flex;
      font-size: $btn-font-size;
      font-weight: $font-weight-medium;
      height: 44px;
      justify-content: center;
      line-height: $btn-line-height;
      padding: 0 $btn-padding-x;
      white-space: $btn-white-space;

      svg {
        height: 1.5rem;
        width: 1.5rem;
        cursor: default !important;
      }

      > div {
        color: inherit !important;

        > span {
          color: inherit;
        }
      }
    }
    .btn-primary {
      @include button-primary-color($color, $contrastColor);

      &:visited {
        text-decoration: none !important;
      }
    }

    .btn-secondary {
      border: $btn-border-width solid $gray-500;
      box-sizing: border-box;
      &:hover {
        background: $white;
        border-color: $gray-600;
        box-shadow: $secondary-btn-hover-box-shadow;
      }
      &:focus {
        background: $gray-300;
        border-color: $gray-600;
        box-shadow: $secondary-btn-focus-box-shadow;
      }
      &:active {
        background: $gray-300;
        border-color: $gray-600;
        box-shadow: $secondary-btn-box-shadow;
      }
      &:focus:active {
        box-shadow: $secondary-btn-focus-box-shadow;
      }
    }

    .btn-link {
      align-items: center;
      border-radius: 0.5rem;
      border-style: none;
      box-shadow: none;
      color: $color;
      display: flex;
      flex-direction: row;
      padding: 10px 24px;

      &:hover {
        background-color: $gray-300;
        background: rgba(0, 0, 0, 0.03);
        color: $color;
        text-decoration: none !important;
      }

      &:active,
      &:focus {
        background-color: $gray-700 !important;
        background: rgba(0, 0, 0, 0.07) !important;
        text-decoration: none !important;
      }
    }

    .btn-icon {
      padding: 10px 24px 10px 16px;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }

      [class^="icon-"] {
        font-size: 1.5rem;
      }

      // targets a span or div that is an immediate sibling of svg
      svg + span,
      div + span,
      [class^="icon-"] + span {
        margin-left: 0.75rem;
        flex: auto;
        display: flex;
        align-self: center;
        padding-top: 2px;
      }

      // because small is defined as .875rem, we multiple the baseline values to be proportional
      &.btn-sm {
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;

        svg {
          height: 1.25rem;
          width: 1.25rem;
        }

        div.google-icon svg {
          height: 18px;
          width: 18px;
        }

        // targets a span that is an immediate sibling of svg
        svg + span,
        div + span {
          padding: 2px 0;
          margin-left: 0.25rem;
        }
      }

      // because large is defined as 1.25rem, we multiple the baseline values to be proportional
      &.btn-lg {
        padding: $btn-icon-padding-y * 1.25 $btn-icon-padding-x * 1.25;

        svg {
          height: 24px * 1.25;
          width: 24px * 1.25;
        }

        div.google-icon svg {
          height: 20px * 1.25;
          width: 20px * 1.25;
        }

        // targets a span that is an immediate sibling of svg
        svg + span,
        div + span {
          padding: 5px * 1.25 0;
          margin-left: 10px * 1.25;
        }
      }
    }

    // for no text (only icon button)
    .btn-no-text {
      flex: 1 0 auto;
      padding: 0 0.75rem;

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      // does not look right with btn-block, so we'll unset the flex square values
      &.btn-block {
        &:before {
          padding: 0;
        }
      }
    }
    .btn-sm {
      padding: 0 0.5rem;
      height: 28px;
      font-size: $btn-font-size-sm;
      border-radius: $btn-border-radius;
      // for icon only button
      &.btn-no-text {
        padding: 0.25rem 0.5rem;

        svg {
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
    .btn-lg {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      font-size: $btn-font-size-lg;
      border-radius: $btn-border-radius;
      // for icon only button
      &.btn-no-text {
        padding: 0.625rem * 1.25 0.75rem * 1.25;

        svg {
          width: 1.5rem * 1.25;
          height: 1.5rem * 1.25;
        }
      }
    }
    .btn-danger {
      background: #ff4440;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      &:hover {
        background: #ff6966;
        border-color: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: $secondary-btn-hover-box-shadow;
      }
      &:active,
      &:focus,
      &:focus:active,
      &:not(:disabled):not(.disabled):active:focus {
        background: #cc3633;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      }
    }

    .btn-dangerous {
      box-sizing: border-box;
      background: #fff !important;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      color: #ff4440 !important;

      &:hover {
        background-color: $gray-300;
        background: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
        text-decoration: none !important;
      }

      &:active,
      &:focus {
        background-color: $gray-700 !important;
        background: rgba(0, 0, 0, 0.07) !important;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
        text-decoration: none !important;
      }
    }
//   }
// }

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.isSubmitting {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
}
