@import 'piconetworks/pkg-picore-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);

.menuContainer {
  background-color: $color-white;
  border-radius: 0.5rem;
  border-top: 0.5rem solid $color;
  color: $landing-dark-text-color;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  overflow: hidden;

  p, span, label, div, h1, h2, h3, h4, h5, h6 {
    color: $black;
  }

  > * {
    ::-webkit-scrollbar {
      display: none;
    }
  }

}

.container {
  height: 285px;
  overflow: auto;
  padding: 0 1.5rem;
}

.planOption {
  padding: 1.25rem 0 0.75rem;

  .mainMenuItem {
    color: $color;
  }
}

.mainMenuContainer {
  padding: 0;

  .accountWrapper {
    align-items: flex-start;
    background-color: $color;
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 1.25rem 2px 1.625rem;
    position: relative;
    z-index: 1;
  }

  .accountHeader {
    color: $contrastColor;
    font-weight: $font-weight-bolder;
    font-size: 1.25rem;
    margin-top: 0.25rem;
  }

  .email {
    color: $contrastColor;
    font-size: 0.875rem;
    font-weight: $font-weight-bold;
    line-height: 1rem;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .menuAvatar {
    line-height: 58px;
    margin-bottom: -2rem;

    & div[class*="avatar"] {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), $color;
      font-size: 3rem !important;
    }
  }

  .mainNavigation {
    max-height: 291px;
    overflow-y: auto;
    padding: 0.7rem 1.5rem 1.5rem 0.5rem;

    li {
      margin: 0;
    }
  }
}

.menuHeader {
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #dddce0;
  display: flex;
  margin-bottom: 1.25rem;
  padding: 1rem 1.5rem;

  .backIcon {
    display: flex;
    margin: 0 0.5rem 0 -0.25rem;

    svg path {
      fill: $color !important;
    }

    span {
      cursor: pointer;
      font-size: 1.25rem;
    }
    
  }
  h3 {
    font-size: 1.25rem;
  }
  &.withoutIcon {
    padding-bottom: 0;
    h3 {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 27px;
    }
  }
  &.shadow {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
  }
  &.notificationHeader {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.mainMenuItem {
  display: flex;
  align-items: center;
  transition: 0.2s opacity;
  svg path {
    fill: $color !important;
  }
  a {
    text-decoration: none !important;
  }
  &.listItemFluid {
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }
  &.deleteLinkItem {
    color: $red;
    padding: 0.5rem 0;

    path {
      fill: $red !important;
    }
  }
  &:hover {
    opacity: 0.8;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
    &:first-of-type {
      display: flex;
    }
  }
  .imgContainer {
    border-radius: 4px;
    height: 1.5rem;
    margin-right: 0.75rem;
    width: 1.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &.circle {
      border-radius: 50%;
    }
  }
  .regularColor {
    color: $landing-dark-text-color;
    strong {
      font-weight: 600;
    }
  }
  .emptyListItem {
    color: $landing-lighter-gray-color;
  }
}
.listTitle {
  color: $landing-gray-color2;
  font-size: 0.75rem;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0;
  text-transform: uppercase;
}

.menuDescription {
  font-size: 1rem;
  p {
    padding: 0;
    margin: 0 0 8px;
    line-height: 1.5;
    span {
      strong {
        font-weight: 600;
      }
    }
  }
}

.menuWrapper {
  .verificationWarning {
    button {
      background-color: $gray-100;
      font-size: 0.875rem;
      margin: 0 0 0 50px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &--pw {
      margin: -0.5rem auto 0.5rem;
      display: grid;
      grid-template-columns: max-content 1fr;
      background-color: $gray-100;
      font-size: 0.875rem;
      color: $color;
      padding: 1rem;

      svg {
        margin-right: 0.75rem;
      }

      p {
        margin: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.approveButtonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0;
  width: 100%;
}
.cancel,
.button {
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}
.cancel {
  margin-right: 0.5rem;
}
.secureText {
  font-size: 14px;
  background: #f4f4f4;
  color: #00c590;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  text-align: center;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 8px;
  }
  a {
    color: #00c590;
    text-decoration: none;
    font-weight: 600;
  }
}

.donationDescription {
  color: #aaa;
  font-size: 14px;
  margin: 12px 0 8px;
}
.donationSubmitButton {
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 2.5rem;
  padding: 0.5rem 1.5rem;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.list {
  cursor: default !important;
  margin-bottom: 0.75rem;
}

.invisible {
  display: none;
}

.background {
  padding: 1rem;
  min-height: 300px;
  background-repeat: repeat-y;
  background-size: auto;
  margin-top: -1.25rem;
  .notification {
    color: $landing-dark-text-color;
    padding: 1rem;
    margin-top: 0.5rem;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.16);
    font-size: 1rem;
    .markdown {
      color: black;
      padding: 0;
      text-align: left;
      p {
        color: black;
        padding: 0;
        text-align: left;
      }
    }
  }
  &.noBg {
    background: none;
  }
}

.hoverCursorPointer {
  &:hover {
    cursor: pointer;
  }
}

.warningTooltipContainer {
  display: flex;
  width: 100%;
}

.warningTooltip {
  margin-left: auto;
}

.warningTooltipIcon {
  padding: 10px;
  font-size: 16px;
  &::before {
    color: inherit !important;
  }
}

// :global form {
//   text-align: left;
// }

.wizardContentClass {
  max-width: 100% !important;
}

.iconContainer {
  &.left {
    left: 1rem;
  }
  &.right {
    right: 1rem;
  }
}
.mewslettersMenuWrapper {
  padding-top: 1.5rem;
  max-height: 18rem;
}

.checkboxMenu {
  margin-bottom: -0.5rem;
}

.checkboxTitle {
  &.dark {
    color: $landing-dark-text-color;
  }
  label {
    color: $black;
    font-family: $font-primary;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.checkboxDesc {
  label {
    color: $gray-500;
    font-family: $font-primary;
    font-size: 0.875;
    font-weight: 400;
    line-height: 1.25rem !important;
  }
}
.checkboxContent {
  color: $landing-dark-text-color !important;
}
.buttonGroup {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  padding: 12px 0px 8px;
  width: 100%;
}
.hidden {
  display: none;
}
.error {
  color: $landing-light-red-color;
  font-size: 0.75rem;
  line-height: 1.2rem;
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.newsletterContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &_title {
    margin: 1.25rem 0 .25rem;
    font-weight: 700;
  }
}

.chooseNewsletterItemContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.chooseNewsletterItem {
  box-sizing: border-box;
  margin: 10px 0;

  width: 50%;

  &.singleColumn {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  div {
    margin: 0px;
  }
}

.tooltip {
  > div:last-child {
    transform: translate(26.273px, 0px) !important;
  }
}

.menuWrapper {
  height: 100%;
  line-height: 1.5;
  min-height: 312px;
  overflow: auto;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  h3 {
    color: #120a20;
    font-weight: 600;
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    color: $landing-gray-color;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  nav,
  ul {
    margin: 0;
    padding-left: 0;
    li {
      list-style: none;
      div {
        font-size: 1rem !important;
        font-weight: 400;
        margin: 0;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        font-size: 1rem;
      }
    }
    &.btnLinkGroup {
      margin-left: -1rem;
    }
  }
}
// a {
//   color: inherit;
// }

.btnLinkGroup {
  margin-left: -1rem;
}

.hideBackground {
  background-color: transparent;
  .menuWrapper {
    padding: 0;
  }
}

.contentFooter {
  background-color: #fafafa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: $landing-gray-color;
  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  padding: 10px 0.75rem;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: auto;
    text-align: left;
  }

  span {
    color: $landing-gray-color;
  }

  a {
    color: $landing-gray-color;
    text-decoration: none;
    transition: 0.2s;
    text-decoration: underline;

    &:hover {
      color: $landing-gray-color;
    }
  }
}
.noBottomMargin {
  margin-bottom: 0;
}
.tabs {
  display: flex;
  justify-content: center;
  padding: 1.25rem 0;
  background-color: white;
  .tab {
    position: relative;
    cursor: pointer;
    display: flex;
    height: 64px;
    width: 64px;
    align-items: center;
    border-radius: 50%;

    svg {
      margin: auto;
    }

    &--active {
      background-color: #d4ebfd;

      &:first-child {
        background-color: #d4f2e2;
      }
    }

    &:first-child {
      margin-right: 2.5rem;
    }

    .triangle {
      position: absolute;
      bottom: -35px;
      left: 0;
      right: 0;
      margin: auto;
      width: 24px;
      height: 24px;
      background-color: #f8f8f8;
      transform: rotate(45deg);
      border-radius: 6px;
    }
  }
}
.loginContainer {
  padding: 20px 24px;
  background: #f8f8f8;
  :global {
    .iti {
      width: 100%;
      display: block;
      border-radius: 0.5rem;
    }
  }
  &.mobile {
    label {
      // display: none;
    }
  }
  &.tabsExists {
    margin-top: 0;
  }
  &.white {
    background-color: white;

    h2 {
      display: none;
    }

    div > div > label {
      text-align: start !important;
    }
  }
  .field {
    margin-bottom: 12px;
  }
  .social {
    display: flex;
    flex-direction: column;
    width: 100%;
    .socialContainer {
      div {
        flex-direction: column;
      }
      button {
        max-width: 100%;
        flex: auto;
        div {
          font-weight: 500;
        }
      }
    }
  }
}

.divider {
  span {
    margin: 20px 0;
    font-size: 12px;
    font-weight: 700;
  }
}
.errorMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 20px;
}
.tryAgain {
  margin-bottom: 12px;
}
.createAccountButton {
  margin-bottom: 20px;
}

.bottomCTA {
  background-color: #f8f8f8;

  .bottomText {
    color: rgba(170, 167, 176, 1);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    line-height: 14px;
    margin-bottom: 0;
    &.top {
      margin-bottom: 12px;
    }
    .linkTrypico {
      color: rgba(170, 167, 176, 1);
      &:hover{
        color: rgba(170, 167, 176, 1);
      }
    }
    .bottomLink {
      margin-left: 4px;
      color: $color;
      cursor: pointer;
      padding-bottom: 1.25rem;
    }
  }
}
.loginHeight {
  height: 420px !important;
  background-color: #f8f8f8 !important;
  overflow-y: auto !important;
}
.loginWrapper {
  @include media-breakpoint-down(lg) {
    background-color: #f8f8f8;
    min-height: 335px;
    padding-bottom: 20px;
  }
}
.signupButton {
  margin-top: 1.25rem;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}

.editPhoneContainer {

  :global {
    .iti {
        width: 100%;
        display: block;
        border-radius: 0.5rem;
    }
  }
  .buttonContainer{
    display: flex;
    justify-content: flex-end;
    .button{
      cursor: pointer;
    }
  }
  .description{
    margin-top: 12px;

  }

  .button{
    &:hover{
      background-color: transparent;
    }
  }
  .tinyText{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #CCCBCE;
  }
  .descriptionText{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}
.showTermsText{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.disclaimer {
  color: #aaa7b0;
  font-size: 0.875rem;
  margin: 2rem 0;

  span {
    font-weight: $font-weight-bolder;
  }
}
.passwordReset {
  font-size: 0.75rem;

  span {
    cursor: pointer;
    margin-left: 0.25rem;
    font-weight: bold;
  }
}
.manageUpdates {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1.5rem;

  &_header {
    font-size: 1rem;
  }

  &_item {
    cursor: pointer;
    margin-top: 1.5rem;

    label,
    > div {
      cursor: pointer;
      margin: 0;
      font-size: 1rem !important;
      line-height: 1.25rem;
    }
  }

  div {
    padding: 0 !important;
  }

  svg {
    margin-right: 10px;
  }

  &--disabled {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.checkboxSubscribe {
  &_check {
    opacity: 0;
  }
}
.markdown {
  color: black;
  padding: 0;
  text-align: left;
  p {
    color: black;
    padding: 0;
    text-align: left;
  }
}
