@import 'piconetworks/pkg-storybook-styles/scss';

.gridPaneContainer {
  // box-shadow: $box-shadow;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.gridPaneContainer:last-child {
  // margin-bottom: 7.5rem;
}

.gridPaneChild {
  padding: 1rem 1rem 1.25rem;
  background-color: $white;
}

.withShadow {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}