@import 'piconetworks/pkg-picore-styles/scss';

$box-shadow-color-from: rgba(0, 0, 0, 0.06);
$box-shadow-color-to: rgba(0, 0, 0, 0.16);

.modal {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 3rem;
    position: relative;
    z-index: 2;
    width: 100%;
    background: $color-white;
    border-radius: 6px;
    text-align: center;

    -ms-box-shadow: 0 1px 6px $box-shadow-color-from,
        0 2px 32px $box-shadow-color-to;
    -o-box-shadow: 0 1px 6px $box-shadow-color-from,
        0 2px 32px $box-shadow-color-to;
    box-shadow: 0 1px 6px $box-shadow-color-from,
        0 2px 32px $box-shadow-color-to;

    @include media-breakpoint-up(md) {
        max-width: 672px;
        height: 100%;
        min-height: 450px;
        margin: 0 auto;
        padding-top: 0;
    }

    @include media-breakpoint-down(sm) {
        border-radius: 0;
        box-shadow: none;
        flex-grow: 1;
    }
}
