@import 'piconetworks/pkg-picore-styles/scss';

.contentFooter {
    align-items: center;
    background-color: $landing-light-gray-color;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: $landing-gray-color;
    display: flex;
    font-size: 0.75rem;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    
    @include media-breakpoint-down(sm) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        justify-content: flex-start;
        margin-top: auto;
        text-align: left;
    }

    span {
        color: $landing-gray-color;
    }

    a {
        color: $landing-gray-color;
        font-weight: 600;
        text-decoration: none;
        transition: 0.2s;
        &:hover {
            opacity: 0.7;
            text-decoration: underline;
        }
    }
}
