@import 'piconetworks/pkg-storybook-styles/scss';

.description {
    font-size: 14px;
    font-weight: 500;
    color: #aaaaaa;
    margin-top: 8px;
    text-align: left;
    &.error {
        color: #f66962;
    }
}
