@import 'piconetworks/pkg-storybook-styles/scss';

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  div {
      margin-bottom: 0;
  }

    &.visible {
        &::after {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    &.loading {

        &::after {
          color: $gray-700;
          display: block;
          background-position: center;
          animation: spinner-border $spinner-animation-speed linear infinite;
        }
    }

  &::after {
      display: none;
      font-size: 12px;
      content: attr(data-validation);
      position: absolute;
      right: 12px;
      background-repeat: no-repeat;
      background-position: left;
      padding: 5px 0px 5px 28px;
  }
}
// :global {
//   .validation-danger {
//       color: $danger;
//       &::after {
//           background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm-3.29525886 5.29248734c-.38998312-.38998312-1.02227068-.38998312-1.4122538 0s-.38998312 1.02227068 0 1.4122538l3.29525886 3.29525886-3.29525886 3.2952589c-.38998312.3899831-.38998312 1.0222706 0 1.4122538.38998312.3899831 1.02227068.3899831 1.4122538 0l3.29525886-3.2952589 3.2952589 3.2952589c.3899831.3899831 1.0222706.3899831 1.4122538 0 .3899831-.3899832.3899831-1.0222707 0-1.4122538l-3.2952589-3.2952589 3.2952589-3.29525886c.3899831-.38998312.3899831-1.02227068 0-1.4122538-.3899832-.38998312-1.0222707-.38998312-1.4122538 0l-3.2952589 3.29525886z' fill='%23FF4440' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
//       }
//   }
//   .validation-success {
//       color: $success;
//       &::after {
//           background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10 0c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm5.6845058 5.83742051c-.4263673-.44719343-1.1076892-.44894255-1.5283684-.01082225l-5.57959797 5.93530224-2.720151-2.88602764c-.42484987-.44123137-1.11454898-.44356653-1.5379643-.0025967-.42636728.44404418-.42303888 1.15631524.00084078 1.59776864l3.4897725 3.6966451c.42737369.4450923 1.11133946.4412294 1.53548443-.0005003l6.33866276-6.72583741c.419652-.43705052.4247365-1.15983441.0013212-1.60393168z' fill='%2328BF6F' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
//       }
//   }
//   .validation-warning {
//       color: $warning;
//       &::after {
//           background-image: url("data:image/svg+xml,%3Csvg height='17' viewBox='0 0 20 17' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-2 -4)'%3E%3Cpath d='m0 0h24v24h-24z'/%3E%3Cpath d='m4.46889451 20.9999987c-.35188519 0-.69754185-.0928401-1.00207254-.2691474-.95592348-.5534294-1.28220942-1.7770016-.72878003-2.7329251l7.53110476-13.00827188c.175087-.30242304.426357-.55369301.72878-.72878003.9559235-.55342938 2.1794958-.22714345 2.7329252.72878003l7.5311047 13.00827188c.1763073.3045307.2691475.6501874.2691475 1.0020725 0 1.1045695-.8954305 2-2 2zm6.74710549-6.719999h1.568l.336-3.0879998.084-2.07200001h-2.408l.084 2.07200001zm.784 4.3880002c.4106687 0 .7466654-.1399986 1.008-.42s.392-.6253313.392-1.036-.1306654-.7559986-.392-1.036-.5973313-.42-1.008-.42-.7466654.1399986-1.008.42-.392.6253313-.392 1.036.1306654.7559986.392 1.036.5973313.42 1.008.42z' fill='%23ffc107'/%3E%3C/g%3E%3C/svg%3E%0A");
//       }
//   }
//   .validation-loading {
//       &::after {
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23AAA7B0' fill-rule='evenodd' d='M12,0 C18.627417,0 24,5.372583 24,12 C24,14.258231 23.3762203,16.3707704 22.2915649,18.1747141 L20.5763803,17.1454684 C21.4802128,15.6422098 22,13.8818063 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,13.8822261 2.52001916,15.6429951 3.42422463,17.1464744 L1.70903973,18.1757196 C0.624011557,16.3715554 0,14.2586506 0,12 C0,5.372583 5.372583,0 12,0 Z'/%3E%3C/svg%3E%0A");        }
//   }
// }

@keyframes spinner-border {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
