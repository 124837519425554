@import 'piconetworks/pkg-storybook-styles/scss';

.badge {
    border-radius: .5rem;
    padding: .3rem .6rem;
    max-width: fit-content;
    letter-spacing: .05rem;
    line-height: 1;
    @each $color, $value in $theme-colors {
      &.#{$color} {
        @include badge-variant($value);
      }
    }
}

.caps {
  text-transform: uppercase;
}
