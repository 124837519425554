@import 'piconetworks/pkg-storybook-styles/scss';

.feedback {
  padding: .75rem 1rem;
  @each $color, $value in $theme-colors {
    &.#{$color} {
      @include badge-variant($value);
    }
  }
  .text {
    padding-left: .75rem;
  }
}

