// the sequence of CSS styling is used to override each other in this file.
// changing sequence might result in changes in styling.

@import 'piconetworks/pkg-storybook-styles/scss';

@mixin onFocusPrefix(
  $prefixBorder,
  $prefixBackground,
  $prefixTextColor,
) {
  &:focus-within {
      border: $border-width solid $prefixBorder;
      background-color: $prefixBackground;
      color: $prefixTextColor;
  }
}

@mixin onFocusInput(
  $inputTextColor,
  $inputBorder,
  $inputBackground,
  $inputPlaceHolder
) {
  &:focus-within {
    &.input {
      color: $inputTextColor;
      border-color: $inputBorder;
      background-color: $inputBackground;
      &::placeholder {
          color: $inputPlaceHolder;
      }
    }
  }
}

@mixin onHover($border-color) {
    &:hover {
        border-color: $border-color;
    }
}

.container {
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    margin-bottom: 0;
    border: $border-width solid $gray-500;
    background-color: $white;
    color: $black !important;
    margin: 0;
    border-radius: $border-radius;
    height: 44px;
    outline: none;
    padding: 0 $input-padding-x;
    font-size: $input-btn-font-size;
    font-weight: $input-font-weight;
    transition: 0.2s ease;
    line-height: $input-line-height;
    &::placeholder {
        color: $gray-500;
    }
    @include onHover($gray-700);
}

.inputIsPrefix {
  border-left: 0;
  border-radius: 0px $border-radius $border-radius 0px;
}

.inputIsInlineLeft {
  padding-left: 3.5rem;
}
.inputIsInlineRight {
  padding-right: 9.5rem;
}

.prefix {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 41px;
  min-height: 44px;

  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $gray-400;
  border: $border-width solid $gray-500;
  border-radius: $border-radius 0 0 $border-radius;

  color: $gray-600;
  font-size: 17px;
  font-weight: 500;

  transition: 0.2s ease;

  span {
      margin-top: -3px;
  }

  @include onFocusPrefix(
    $prefixBorder: $purple,
    $prefixBackground: $btn-link-disabled-color,
    $prefixTextColor: $purple
  );
  @include onHover($gray-700);
}

.prefixError{
  border-color: $form-feedback-invalid-color;
  background-color: lighten($form-feedback-invalid-color, 38%);
  color: $form-feedback-invalid-color;
  @include onHover($form-feedback-invalid-color);
}

.inputError{
  border-color: $form-feedback-invalid-color;
  background-color: lighten($form-feedback-invalid-color, 38%);
  color: $form-feedback-invalid-color;
  &::placeholder {
      color: lighten($form-feedback-invalid-color, 25%);
  }
  @include onHover($form-feedback-invalid-color);
}

.prefixDisabled {
  border-color: $gray-500;
  background-color: $gray-400;
  color: $gray-600;
  @include onHover($gray-500)
}

.prefixInline {
  background-color: $white;
  border-right: none;
  color: $gray-700;
  font-size: 1rem;
  min-width: auto;
  padding: 0 0 0 1rem;
  width: auto;

  + input {
    padding-bottom: 2px;
    padding-left: 2px;
  }
}

.inputDisabled {
  border-color: $gray-500;
  background-color: $gray-300;
  color: $gray-700;

  &::placeholder {
      color: $gray-500;
  }
  @include onHover($gray-500);

}
