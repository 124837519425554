@import 'piconetworks/pkg-storybook-styles/scss';

.field {
  flex: auto;
  margin-bottom: 1.25rem;
  .name {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    .required {
      font-size: 14px;
      color: $form-feedback-invalid-color;
    }
  }
}
