@import 'piconetworks/pkg-picore-styles/scss';

.passwordContainer {
    .strength {
        margin: -0.75rem 0 0.5rem;
    }

    &_input {
        position: relative;

        input {
            padding-left: 44px;
            font-size: 1rem;

            // place holder dots
            // are different sizing with 1rem font size
            // so try to get it to match with
            // the dots when password input is filled
            &::placeholder {
                position: absolute;
                font-size: 10px;
                letter-spacing: 0.75px;
                bottom: 13px;
            }
        }

        .showPassword {
            position: absolute;
            left: 0;
            top: 2rem;
            height: 44px;
            width: 2rem;
            display: flex;
            align-items: center;
            padding-left: 1rem;
            cursor: pointer;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        &_error {
            input {
                background: #ffdad9;
                color: #ff4440;
            }
        }
    }
}
