@import 'piconetworks/pkg-storybook-styles/scss';

.container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    .label {
        margin: 0;
        text-align: left;
        color: $gray-900;
    }
}


/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $white;
    border-radius: 50%;
    border: $border-width solid $gray-500;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    border-color: $gray-700;
}


.container input:disabled ~ .checkmark {
    background-color: $gray-400;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: $white;
    border-color: var(--block-contrast-color, #000000);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark {
    .inner {
        content: '';
        position: absolute;
        display: none;
    }
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark {
    .inner {
        display: block;
    }
}

/* Style the indicator (dot/circle) */
.container .checkmark {
    .inner {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--block-contrast-color, #000000);
    }
}
