@import "styles/styles";

$color: var(--linkColor);
$contrastColor: var(--contrastColor);
$pageContrastColor: var(--page-contrast-color);

@keyframes bounceInUp {
  from,
  60%,
  75%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -10px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, 5px, 0) scaleY(0.95);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
  animation-delay: 500ms;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -10px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.layoutContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  // only apply these global styles to elements that are not descendants of .RemoteMarkdown

  :global {
    :not([class*="RemoteMarkdown"]) > {
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0.5rem;
        // font-weight: 700;
        line-height: 1.2;
      }
      .block-section {
        margin-bottom: 2rem;
      }

      h2, .h2 {
        font-size: 1.25rem;
      }

      h3, .h3 {
        font-size: 1rem;
      }
      h4, .h4 {
        font-size: 0.875rem;
      }
      h5, .h5 {
        font-size: 0.75rem;
      }
      h6, .h6 {
        font-size: 1rem;
      }

      .SingleDatePicker {
        width: 100%;
        .SingleDatePickerInput{
          color: $black !important;
          width: 100%;
          #Single_date_picker{
            border: none !important;
          }
        }
      }


      input[type="text"],
      input[type="tel"],
      input[type="email"],
      textarea {
        border: 1px solid $landing-border-color !important;

        &:focus,
        &:active,
        &:focus:active {
          border-color: $color !important;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.4 !important;
        }
      }
    }
  }
}
.mainWrapper {
  flex: 1;
  margin-bottom: 5rem;
  width: 100%;
  &.nfcSource {
    margin-bottom: 6.5rem;
  }
  &.showClaimThisPage {
    margin-top: 60px;
  }
  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 600px;
  }
  .headerClass {
    top: 0;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    &.isNotAuthed{
      position: absolute;
      top: 1rem;
      align-items: flex-start;
      @include media-breakpoint-up(md) {
        top: 1rem;
      }
    }

    &.showClaimThisPage {
      top: 65px;
    }

    @include media-breakpoint-up(md) {
      max-width: 600px;
      top: 1.5rem;
    }

    > div {
      flex: 1;
    }

    .link {
      display: flex;
      align-items: center;
      justify-self: center;
      span {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 500;
        margin-right: 8px;
        &:before {
          font-weight: 400;
          color: inherit;
        }

        i {
          margin-right: 0.5rem;
        }
      }
      .websiteLink {
        text-decoration: none;
        display: flex;
        align-items: center;
        .headerIcon {
          font-size: 24px;
          margin-top: 1px;
        }
      }
    }
    .headerRight {
      font-size: 0.875rem;
      display: flex;

      p {
        line-height: 1.5;
      }
      .accountLink {
        text-decoration: none;
      }
      .headerDescription {
        margin-right: 0.5rem;
      }
      .loginButton {
        margin: auto;
      }
      .headerButton {
        border-radius: 8px;
        color: $landing-dark-text-color;
        cursor: pointer;
        font-size: 0.875rem;
        padding: 4px 4px 4px 12px;
        text-decoration: none;
        transition: 0.2s;
        height: 32px;
        display: flex;
        align-items: center;
        width: 100%;
        &.login {
          padding: 7px 16px;
        }

        &:hover {
          background-color: hsla(0, 0%, 100%, 0.9);
        }
        strong {
          font-weight: 600;
          margin-right: 8px;
        }
      }
    }
  }
  .banner {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 136/71;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -1rem;
    border-bottom-left-radius: var(--block-border-radius);
    border-bottom-right-radius: var(--block-border-radius);
    + .brandHeader {
        .logo {
          padding: 0;

          h1 {
            padding-top: 2rem;
          }

          .bannerAvatar {
            margin-top: -40px;
            img {
              aspect-ratio: 1/1;
            }

            + h1 {
              padding-top: 0;
            }
          }
        }
    }
  }

  .brandHeader {
    color: var(--page-contrast-color);
    margin: 0;
    padding: 0 1.5rem 2rem;
    text-align: center;

    .logo {
      padding-top: 1rem;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      z-index: 1;

      div {
        img {
          border: 4px solid $trypico-light-bg-color;
          height: 80px;
          width: 80px;
        }
      }
    }

    .bannerAvatar {
      margin-bottom: 0.75rem;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0;
      flex: auto;
    }

    .socialLinks {
      margin: 1rem 0 0 0;
      padding: 0 0.75rem;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0 auto 0;
    position: relative;
    z-index: 2;
  }

  .mainContentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    border-radius: 0;

    &.inIFrame {
      border-radius: 0.5rem;
    }

    .mainContent {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    div[class*="wizardHeader"] {
      margin-top: -8px;
    }

    div[class*="wizardContent"] {
      max-width: unset;
    }
  }

  .menuHeader {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 16px 16px 12px;

    .backIcon {
      display: flex;
      margin: 0 6px 0 -4px;
      span {
        cursor: pointer;
        font-size: 1.25rem;
      }
    }
    h3 {
      font-size: 1.25rem;
    }
    &.withoutIcon {
      padding-bottom: 0;
      h3 {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 27px;
      }
    }
    &.shadow {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
    }
    &.notificationHeader {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .mainMenuItem {
    display: flex;
    align-items: center;
    &.listItemFluid {
      justify-content: space-between;
    }
    &.deleteLinkItem {
      color: rgb(255, 94, 91);
      padding: 0.5rem 0;
    }
    &:hover {
      opacity: 0.8;
    }
    a {
      &:first-of-type {
        display: flex;
      }
    }
    .imgContainer {
      background-color: rgb(255, 231, 230);
      border-radius: 4px;
      height: 1.5rem;
      margin-right: 0.75rem;
      width: 1.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &.circle {
        border-radius: 50%;
      }
    }
    .regularColor {
      color: $landing-dark-text-color;
      strong {
        font-weight: 600;
      }
    }
    .emptyListItem {
      color: $landing-lighter-gray-color;
    }
  }
  .listTitle {
    color: $landing-gray-color2;
    font-size: 0.75rem;
    letter-spacing: 1px;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
  }
  .menuDescription {
    font-size: 1rem;
    p {
      padding: 0;
      margin: 0 0 8px;
      span {
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .verificationWarning {
    background-color: rgb(255, 231, 230);
    border-radius: 4px;
    color: $landing-light-red-color;
    font-size: 1rem;
    margin: 8px 0;
    padding: 4px 16px;
    text-align: center;
    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        text-decoration: none;
        opacity: 0.9;
        transition: 0.2s;
      }
    }
  }
}
.approveButtonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0 8px;
  width: 100%;
  .cancel {
    color: $landing-light-red-color;
    margin-right: 24px;
    cursor: pointer;
    font-size: 16px;
  }
  .button {
    background-color: $landing-light-red-color;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 2.5rem;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    &.danger {
      background-color: #ff5e5b;
      padding: 10px 20px;
      line-height: 2;
      font-weight: 600;
      color: #fff;
    }
  }
  .cancel,
  .button {
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
}
.secureText {
  font-size: 14px;
  background: #f4f4f4;
  color: #00c590;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  text-align: center;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 8px;
  }
  a {
    color: #00c590;
    text-decoration: none;
    font-weight: 600;
  }
}

.hideWhileLoading {
  display: none;
}

.container {
  padding: 0 16px;
  .menuContainer {
    padding: 0.25rem 0 0.5rem;
    &.planOption {
      padding-top: 20px;
      padding-bottom: 12px;
      .mainMenuItem {
        margin-bottom: 16px;
      }
    }
    &.fullPadding {
      padding: 16px 0 12px;
    }
    .accountHeader {
      height: 64px;
      h3 {
        font-size: 1.25rem;
      }
    }
    .navigationList {
      padding: 8px 0 0;
      li {
        margin-bottom: 16px;
      }
    }
  }
}

.donationDescription {
  color: #aaa;
  font-size: 14px;
  margin: 12px 0 8px;
}
.donationSubmitButton {
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 2.5rem; */
  /* padding: .5rem 1.5rem; */
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
}
.list {
  cursor: default !important;
}
.avatar {
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}
.menuAvatar {
  font-size: 28px;
  height: 64px;
  line-height: 58px;
  width: 64px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invisible {
  display: none;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.footerContainer {
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.2);
  border-radius: 1.5rem;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  bottom: 20px;
  padding: 0.5rem;
  margin: 0 auto;
  position: fixed;
  text-align: center;
  z-index: 2147483647;
  &.fullWidth {
    border-radius: 2rem;
    left: 10px;
    padding-right: 1.5rem;
    max-width: 660px;
    right: 10px;
  }
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }

  .footerContent {
    display: flex;
    justify-content: space-between;
  }

  .footerButton {
    position: relative;
    display: block;
    padding: 10px 14px 10px 10px;
    background: white;
    border-radius: 1.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    color: $trypico-dark-color;
    font-family: $system-fonts;
    font-size: 14px;
    font-weight: 600;
    mix-blend-mode: normal;
    -webkit-transition: 0.2s;
       -moz-transition: 0.2s;
         -o-transition: 0.2s;
            transition: 0.2s;
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      text-decoration: none;
    }
  }

  .footerButtonShimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(50% 143.75% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(226.75deg, #2AD0CA 3.67%, #E1F664 25.32%, #FEB0FE 43.85%, #ABB3FC 57.24%, #5DF7A4 69.16%, #F5EB91 84.51%, #58C4F6 98.31%);
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 1.5rem;
    transition: mask-position 2s ease,-webkit-mask-position 2s ease;
    z-index: 1;
    -webkit-mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
            mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
    -webkit-mask-size: 800%;
            mask-size: 800%;
    -webkit-mask-position: 0;
            mask-position: 0;
    &:hover {
      -webkit-mask-position: 120%;
              mask-position: 120%;
      opacity: 1;
    }
  }

  .footerButtonLabel {
    position: relative;
    display: flex;
    align-items: center;
    color: #444 !important;
    text-shadow: 0 1px 0 white;
    pointer-events: none;
    z-index: 2;
    
    svg {
      margin-right: 6px;
      filter: drop-shadow(0 1px 0 white);
      &.buttonLogo {
        height: 12px;
        margin: 0 5px;
      }
      path {
        fill: #444 !important;
      }
    }

    span {
      color: #444 !important;
      font-family: $system-fonts;
    }
  }

  a {
    color: rgba(var(--page-contrast-color-rgb), 0.2);
    &:hover {
      color: rgba(var(--page-contrast-color-rgb), 0.5);
    }
  }

  .poweredByNfc {
    color: rgba(var(--page-contrast-color-rgb), 0.7) !important;
    font-family: $system-fonts;
    font-size: 10px !important;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
  }

  .poweredByHype {
    display: block;
    margin-top: 0.5rem;
    text-decoration: none;

    span {
      @media only screen and (max-width: 350px){
        display: none;
      }
      color: rgba(var(--page-contrast-color-rgb), 0.7) !important;
      font-family: $system-fonts;
      font-size: 0.75rem !important;
    }

    svg {
      height: 12px;
      width: 44px;
      margin-bottom: 2px;
      margin-left: 5px;
  
      path {
        fill-opacity: 0.7;
        fill: var(--page-contrast-color);
      }
    }
  }

  
}

.isRounded {
  border-radius: 0.5rem;
}
.isPreviewWrapper {
  position: relative;
  .isPreviewOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}