@import 'piconetworks/pkg-picore-styles/scss';

.socialLogin {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.375rem;
  margin-bottom: -1.5rem;

  .socialButton {
    position: relative;
    flex: 1;
    margin: 0 0.375rem 0.75rem;
    min-width: calc(50% - 0.75rem);
    background: #ffffff;
    color: #000000;

    &:last-child {
      max-width: calc(50% - 0.75rem);
    }
    &:first-child {
      max-width: 100%;
    }
    &:focus {
      box-shadow: none !important;
    }
    .text {
      text-transform: capitalize;
      font-weight: normal;
      margin-left: 1rem;
      color: $black !important;
    }
  }
}

.socialIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  @include media-breakpoint-down(md) {
    left: 0.5rem;
  }

  &.google {
    background-color: $color-white;
  }
}
