@import 'styles/styles';

.headerButton {
  background-color: $white;
  color: $landing-dark-text-color;
  cursor: pointer;
  font-size: .875rem;
  padding: 4px 4px 4px 12px;
  text-decoration: none;
  transition: .2s;
  height: 56px;
  display: flex;
  align-items: center;
  box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.2);

  > * {
    color: inherit;
  }

  @include media-breakpoint-up(md) {
    border-radius: 8px;
    height: 40px;
  }

  &.login{
    padding: 7px 16px;
  }

  &:hover {
      background-color: hsla(0, 0%, 100%, 0.9);
  }
  strong{
    font-weight: 600;
    margin-right: 8px;
  }
  .avatar{
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;
    width: 24px;
  }
}
.wrapper{
  display: flex;
  align-items: center;

}
.showMobileOnly{
  display: none;
}

.showMobile{
  height: 48px;
  padding-left: 20px;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.loginButton{
  box-shadow: none;
  background-color: transparent;
  justify-content: flex-end;
  height: auto;
  padding: 0;
  .button{
    align-items: center;
    background-color: var(--page-contrast-color) !important;
    color: var(--page-button-contrast-color) !important;
    border-radius: 8px;
    border: 1px solid #DDDCE0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    color: $black;
    display: flex;
    flex-direction: row;
    margin-right: 16px;
    padding: 4px 8px;
  }
  &:hover {
    background-color: transparent;
  }
}
.menuContainer{
  top: calc(2rem + 4px) !important;
  right: 16px !important;

  input:focus {
    border-color: var(--linkColor) !important;
  }
}
.menuWrappper{
  overflow: unset !important;
  height: auto !important;
}
