@import 'piconetworks/pkg-storybook-styles/scss';

.container {
    margin-top: 20px;
    .item {
        margin-bottom: 30px;
        input {
            margin-bottom: 0;
            margin-left: 15px;
        }
        .inputContainer {
            input {
                min-height: 30px;
            }
        }
    }
}
.itemButton{
  height: 44px;
}
