@import 'piconetworks/pkg-picore-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);

.formArea {
  label {
    display: block !important;
    font-family: $font-primary !important;
    line-height: 1rem;
    text-align: left !important;
  }
  input {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #ddd !important;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    color: #444;
    font-size: 16px;
    padding: 0 16px;
    text-transform: none;
    transition: border-color 0.2s ease;
    width: 100%;
    min-height: 40px !important;
    height: 40px;
  }
  .buttonContainer {
    margin-top: 2rem;
  }
}
.error {
  background-color: $error-background;
  border-radius: 8px;
  color: $red;
  margin: 0 auto 1.25rem;
  padding: 0.75rem 1rem;
}
.accountContainer {
  .secureText {
    font-size: 14px;
    background: #f4f4f4;
    color: #00c590;
    padding: 8px 12px;
    border-radius: 4px;
    justify-content: center;
    margin: 8px 0;
    text-align: center;
    span {
      margin-right: 8px;
    }
    a {
      color: #00c590;
      text-decoration: none;
      font-weight: 600;
    }
  }
  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .resetForm {
      margin-right: 24px;
      cursor: pointer;
      font-size: 16px;
    }
    button {
      font-weight: 700;
      font-size: 16px;
    }
  }
}
.approveButtonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .cancel {
    margin-right: 0.5rem;
  }
  .cancel,
  .button {
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.passwordStrength {
  font-size: 14px;
}

.PasswordStrengthColor {
  color: #aaa;
  &.color_0 {
    color: #ff5e5b;
  }
  &.color_1 {
    color: #f5a623;
  }
  &.color_2 {
    color: #edd528;
  }
  &.color_3 {
    color: #90c85c;
  }
  &.color_4 {
    color: #00c590;
  }
}

.backerBar {
  background-color: #f4f4f4;
  border-radius: 4px;
  height: 8px;
  position: relative;

  .innerBar {
    margin: 0 !important;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 0%;
    transition: 0.2s all;
    &.color_0 {
      background-color: #ff5e5b;
      width: 20%;
    }
    &.color_1 {
      background-color: #f5a623;
      width: 40%;
    }
    &.color_2 {
      background-color: #edd528;
      width: 60%;
    }
    &.color_3 {
      background-color: #90c85c;
      width: 80%;
    }
    &.color_4 {
      background-color: #00c590;
      width: 100%;
    }
  }
  // transition: .2s;
}

.passwordWarning {
  color: #aaa;
  font-size: 14px;
}

.passwordContainer {
  max-width: 380px;
  margin: 0px auto;

  .showPassword {
    margin-bottom: -24px;
  }
}

.showPassword {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.5rem;
  float: right;
  &:hover {
    cursor: pointer;
  }
  + div {
    clear: both;
  }
}

.wizardHeaderClass {
  h3 {
    font-size: 18px !important;
  }
}

.iconContainer {
  &.color {
    span {
      &:before {
        color: white;
      }
    }
  }
  &.left {
    left: 1rem;
  }
  &.right {
    right: 1rem;
  }
}

.passwordInfo {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  padding-top: 0.5rem;
}

.passwordInput {
  display: flex;
  margin-bottom: 0 !important;

  button {
    width: 48px !important;
    height: 44px !important;
    margin: auto 0 auto 0.5rem !important;

    &:before {
      content: "\ea4f" !important;
      font-family: "picomoon" !important;
      color: white;
      font-size: 24px;
      margin: auto;
      padding: 0 !important;
      position: relative;
    }
  }
}

.container {
  @include media-breakpoint-up(sm) {
    text-align: center;
  }

  .field label {
    margin-bottom: 0.5rem;
    color: #120a20;
    font-size: 1rem;
    font-weight: 400 !important;
    line-height: 20px;
    text-align: center;
  }

  & [class*="otpContainer"] {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 340px;

    input {
      min-width: 41px !important;
      max-width: 41px;
      height: 48px !important;
      padding: 0;
    }
  }

  div[class*="react-notification-root"] {
    align-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
    position: relative;

    div[class*="notification-container-top-center"] {
      margin: 0 auto;
      max-width: 100%;
      position: relative;
    }

    div[class*="notification-parent"] {
      margin-bottom: 1rem;
    }

    div[class*="notification-item"] {
      justify-content: center;
      margin: 0 auto;
    }

    div[class^="notification-container-"] {
      left: 0;
    }
  }
}

.field {
  .otpInput {
    margin-top: 1rem;

    &--error {
      & [class*="field"] {
        input,
        input:focus {
          border-color: #f66962 !important;
        }

        margin-bottom: 0;
      }
    }

    & [class*="field"] {
      margin-bottom: 0;
    }
  }
}

.resendCode {
  align-items: center;
  color: $color;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  margin: 0.75rem 0 1.25rem;
  position: relative;
  text-align: center;

  button {
    animation-direction: reverse;
    animation-name: fadeOut;
    animation-duration: 50ms;
  }
}

.notification {
  animation-delay: 3000ms;
  animation-duration: 300ms;
  animation-name: fadeOut;
  background-color: #ebfaf2;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  height: 44px;
  height: 44px;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 1.5rem 0 1rem;

  > div {
      color: #28bf6f;
      margin: auto;
  }

  @keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
  }

  svg {
      margin: auto .5rem .3rem 0;
  }
}


.disclaimer {
  color: #aaa7b0;
  font-size: 0.875rem;
  margin: 2rem 0;

  span {
    font-weight: $font-weight-bolder;
  }
}

.otpButton {
  margin-bottom: 1rem;
}
