@import 'piconetworks/pkg-storybook-styles/scss';

.label {
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    color: $form-label-color;
    margin-bottom: $form-label-margin-bottom;
    display: inline-block;

    &.secondary {
      color: $gray-700;
    }

    &.error {
      color: $danger;
    }
}
