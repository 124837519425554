@import 'piconetworks/pkg-picore-styles/scss';

$color: var(--linkColor);
$contrastColor: var(--contrastColor);

.container {
    @include media-breakpoint-up(sm) {
        text-align: center;
    }

    label {
        margin: 1rem 0;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
    }

    h2 {
        color: #120a20;
        margin-bottom: 1rem;
        text-align: center;
    }

    .notification {
        background-color: #ebfaf2;
        width: 189px;
        height: 44px;
        margin: 1rem auto;

        > div {
            color: #28bf6f;
            margin: auto;
        }

        svg {
            margin: auto .5rem .3rem 0;
        }
    }

    & [class*='otpContainer'] {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 340px;

        input {
            min-width: 41px !important;
            max-width: 41px;
            height: 48px !important;
        }
    }

    div[class*='react-notification-root'] {
        align-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
        position: relative;

        div[class*='notification-container-top-center'] {
            margin: 0 auto;
            max-width: 100%;
            position: relative;
        }

        div[class*='notification-parent'] {
            margin-bottom: 1rem;
        }

        div[class*='notification-item'] {
            justify-content: center;
            margin: 0 auto;
            box-shadow: none;
        }

        div[class^='notification-container-'] {
            left: 0;
        }
    }
}

.field {
    .otpInput {
        &--error {
            & [class*='field'] {
                input,
                input:focus {
                    border-color: #f66962 !important;
                }

                margin-bottom: 0;
            }
        }

        & [class*='field'] {
            margin-bottom: 0;
        }
    }
}

.otpButton {
    border-color: $color !important;
    color: $contrastColor;
    margin-top: 1.5rem;
    width: 100%;
}

.notification {
    background: $green;
    color: #fff;
    border-radius: 0.5rem;
    font-weight: 500;
    display: table;
    margin: 1.5rem auto;
    padding: 0.625rem 1.375rem;
    text-align: center;
}

.info {
    font-size: 0.75rem !important;
    padding-top: 1rem;
}

.resendCode, .backLink {
    font-size: 0.75rem !important;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}