@import 'piconetworks/pkg-storybook-styles/scss';

.container {
  @include make-container();
  @include make-container-max-widths();
}

// 100% wide container at all breakpoints
.container-fluid {
  @include make-container();
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    @extend .container-fluid;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }

    @each $name, $width in $grid-breakpoints {
      @if ($container-max-width > $width or $breakpoint == $name) {
        .container#{breakpoint-infix($name, $grid-breakpoints)} {
          @extend %responsive-container-#{$breakpoint};
        }
      }
    }
  }
}
