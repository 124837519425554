@import 'piconetworks/pkg-storybook-styles/scss';

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.loading {
  width: $spinner-width;
  height: $spinner-height;
  animation: spinner-border $spinner-animation-speed linear infinite;
}
