@import 'piconetworks/pkg-storybook-styles/scss';

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
