// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$enable-shadows: true;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.25,
        ),
        5: (
            $spacer * 1.5,
        ),
        6: (
            $spacer * 2,
        ),
        7: (
            $spacer * 3,
        ),
        8: (
            $spacer * 6,
        ),
        9: (
            $spacer * 12,
        ),
        10: (
            $spacer * 15,
        ),
    ),
    $spacers
);
$containerWidth: 360px;

//fonts
$system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

//common colors
$color-white: #fff;
$color-black: #000;
//trypico colors
$trypico-dark-purple-color: #212529;
$trypico-dark-color: #120a20;
$trypico-dark-gray-color: #444;
$trypico-gray-color: #808080;
$trypico-gray-color2: #777;
$trypico-gradient-light-purple: #faf6ff;
$trypico-gradient-lighter-purple: #f1e7fd;
$trypico-light-gray-color: #aaa;
$trypico-lighter-gray-color: #ddd;
$trypico-x-light-gray-color: #eee;
$trypico-x-light-gray-color2: #f4f4f4;
$trypico-dark-blue-color: #264981;
$trypico-blue-color: #7a34ff;
$trypico-light-blue-color: #3da7ff;
$trypico-light-purple: #8b8890;
$trypico-x-light-brown-color: #fff9f1;
$trypico-green-color: #00a562;
$trypico-light-green-bg-color: #edfbf4;
$trypico-light-green-color: #59cd90;
$trypico-light-green-color2: #27c940;
$trypico-light-bg-color: #f8f8f8;
$trypico-light-blue-bg-color: #f1f9ff;
$trypico-light-pink-bg-color: #fff1f7;
$trypico-pink-color: #ff3d8f;
$trypico-yellow-color: #ffbd2e;
$trypico-dark-y-color: #6a5f49;
$trypico-red-color: #ff5f57;
$trypico-orange-color: #ffbf69;
$trypico-violet-bg-color: #f7f1ff;
$trypico-brown-bg-color: #fffaf2;

//landing page colors
$landing-dark-text-color: #444;
$landing-gray-color: #aaa;
$landing-gray-color2: #777;
$landing-gray-color3: #7b7b7b;
$landing-xlight-gray-color: #fafafa;
$landing-warm-gray-color: #9a9a9a;
$landing-light-gray-color: #f4f4f4;
$landing-lighter-gray-color: #ddd;
$landing-violet-color: #7a34ff;
$landing-violet-color-2: #8748ff;
$landing-smooth-blue-color: #4285f4;
$landing-light-blue-color: #caaeff;
$landing-light-red-color: #ff5e5b;
$landing-dark-gray-color: #777777;
$landing-silver-color: #dddddd;
$landing-dark-blue-color: #004f80;
$landing-disabled-gray: #eeeeee;
$landing-primary-disabled: #c9adff;
$landing-secondary-disabled: #f8f8f8;
$landing-secondary-disabled-text: #cccccc;
$landing-disabled-blue: #a0c2fa;
$landing-hover-button-blue: #699ef7;
$landing-facebook-blue-color: #1877F2;
$landing-google-blue-color: #4285F4;
$landing-google-red-color: #c53929;
$landing-pantone-color: #00c590;
$landing-border-color: #dddce0;

//error colors
$error-background: #ffefee;
$error-background-second: #ffdedd;
$error-border: #ff5e5b;
$error-textColor: #ff4440;
$error-placeholder: #ffb0ae;

//paths
$S3_BUCKET: 'https://s3.amazonaws.com/development-cms-assets';
