@import 'piconetworks/pkg-picore-styles/scss';
$textColorPage: var(--page-contrast-color);
$textColorBlock: var(--block-contrast-color);
.container {
    :global {
        .block {
            color: $textColorBlock;
        }
        .page {
            color: $textColorPage;
        }
    }
    color: inherit;
    padding: 0.5rem 1.5rem;
    text-align: center;

    a {
        text-decoration: underline;
    }

    h1,
    h2,
    p {
        margin: 0 0 1rem;
    }

    p {
        font-size: 0.875rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.125rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4 {
        font-size: 0.875rem;
        font-weight: bold;
    }

    ol,
    ul {
        padding-left: 1rem;
    }

    blockquote {
        border-left: 5px solid !important;
        border-left-color: inherit;
        color: inherit;
        margin: 1rem 0;
        padding: 1rem;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

