@mixin button-primary-color($linkColor: false, $contrastColor: false) {
  @if $linkColor {
    background-color: $linkColor;
    border-color: rgba($black, 0.05);
    box-shadow: 0 1px 2px rgb($linkColor / 15%), 0 1px 2px rgb(18 10 32 / 15%);

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        ),
        $linkColor;
      border-color: rgba($black, 0.05);
    }

    &:active,
    &:focus,
    &:focus:active,
    &:not(:disabled):not(.disabled):active {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
        ),
        $linkColor;
      border-color: rgba($black, 0.05);

      box-shadow: none !important;
      text-decoration: none !important;
    }
    
    &:disabled {
      background-color: rgba(var(--block-contrast-color-rgb),.3) !important;
      border-color: rgba(var(--block-background-color-rgb),.3) !important;
  
      // background-color: var(--linkColor);
      // border-color: rgba(18, 10, 32, 0.05);
      box-shadow: 0 1px 2px rgb(var(--linkColor)/15%), 0 1px 2px rgba(18, 10, 32, 0.15);
      color: var(--contrastColor);
    }
  } @else {
    background: $purple;
    border-color: rgba($black, 0.05);

    &:hover {
      background: $purple-400;
      border-color: rgba($black, 0.05);
      box-shadow: $primary-btn-hover-box-shadow;
    }

    &:active,
    &:focus:active {
      background: $purple-600;
      border-color: rgba($black, 0.05);
      box-shadow: $primary-btn-box-shadow;
    }

    &:disabled {
      background: $purple;
      border-color: rgba($black, 0.05);
    }
  }

  @if $contrastColor {
    color: $contrastColor;
  } @else {
    color: $white;
  }
}
