@import 'piconetworks/pkg-storybook-styles/scss';

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  span {
    padding: 0 1rem;
    margin: 1.5rem 0;
    color: $gray-700;
    font-size: $font-size-sm;
  }
  &.withLine {
    &:before {
      content: '';
      flex: 1;
      border-bottom: 1px solid $gray-500;
    }
  }

  &.withChildren {
    &:before, &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $gray-500;
    }
  }
}
