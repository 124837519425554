@import 'piconetworks/pkg-picore-styles/scss';

.checkboxMenu {
    margin-bottom: -0.5rem;
}

.checkboxTitle {
    &.dark {
        color: $landing-dark-text-color;
        color: var(--block-contrast-color) !important;
    }
    label {
        font-size: 1rem !important;
        margin-bottom: 0.25rem !important;
        color: var(--block-contrast-color) !important;
        display: block !important;
    }
}

.checkboxDesc {
    label {
        line-height: 16.71px !important;
        font-size: 0.875rem !important;
        color: rgba(var(--block-contrast-color-rgb), 0.7) !important;
        margin-bottom: 0 !important;
    }
}

.checkboxContent {
    color: var(--block-contrast-color) !important;
}

.field {
    $color: var(--linkColor);
    position: relative;

    input:checked + .checkboxContent {
        .outerClassName {
            background-color: #FFFFFF !important;
        }
    }

    svg {
        path {
            stroke: #000000 !important;
        }
    }
}

.chooseNewsletterItem {
    box-sizing: border-box;
    margin-bottom: 1.25rem;
    width: 50%;

    &:last-child {
        margin-bottom: 0;
    }

    &.singleColumn {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    div {
        margin: 0px;
    }
}

.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.tooltip {
    > div:last-child {
        transform: translate(26.273px, 0px) !important;
    }
}

.showMore {
    color: var(--block-contrast-color) !important;
    cursor: pointer;
    font-size: 0.875rem;
    text-align: left;
    margin-top: 1.5rem;

    svg {
        height: 16px;
        margin-left: 5px;
        width: 16px;

        &.isInverse {
            transform: rotate(180deg);
        }

        g {
            fill: var(--block-contrast-color);
        }
    }
}

.blockOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
    z-index: 10;
}
