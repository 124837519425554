@import 'piconetworks/pkg-storybook-styles/scss';

.small {
  font-size: $small-font-size;
  line-height: $line-height-sm;
}

.secondary {
  color: $gray-700;
}

.error {
  color: $danger;
}