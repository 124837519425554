@import "styles/styles";

.claimBlock {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 999999;
    border-radius: 0 !important;
    border: none !important
}