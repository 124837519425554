@import 'piconetworks/pkg-storybook-styles/scss';

@mixin onFocus(
    $prefixBorder,
    $prefixBackground,
    $prefixTextColor,
    $inputTextColor,
    $inputBorder,
    $inputBackground,
    $inputPlaceHolder
) {
    &:focus-within {
        .input {
            color: $inputTextColor;
            border-color: $inputBorder;
            background-color: $inputBackground;
            &::placeholder {
                color: $inputPlaceHolder;
            }
        }
    }
}

@mixin onHover($border-color) {
    &:hover {
        .input {
            border-color: $border-color;
        }
    }
}

.container {
    display: flex;
    align-items: center;
    position: relative;

    .input {
        width: 100%;
        margin-bottom: 0;
        border: $border-width solid $input-border-color;
        background-color: $white;
        margin: 0;
        border-radius: 4px;
        min-height: 4rem;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 17px;
        font-weight: 400;
        transition: 0.2s ease;

        &::placeholder {
            color: $input-placeholder-color;
        }
    }

    .count {
        font-size: .75rem;
        position: absolute;
        bottom: .25rem;
        right: .25rem;
        margin: 0;
    }

    @include onHover($gray-700);
    @include onFocus(
        $input-focus-border-color,
        $input-focus-bg,
        $input-focus-color,
        $black,
        $input-focus-border-color,
        $white,
        $input-placeholder-color
    );
}
.error {
    .input {
        border-color: $form-feedback-invalid-color;
        background-color: lighten($form-feedback-invalid-color, 38%);
        color: $form-feedback-invalid-color;

        &::placeholder {
            color: lighten($form-feedback-invalid-color, 25%);
        }
    }

    @include onHover($form-feedback-invalid-color);

    @include onFocus(
        $input-focus-border-color,
        $input-focus-bg,
        $input-focus-color,
        $black,
        $input-focus-border-color,
        $white,
        $input-placeholder-color
    );
}
.disabled {
    .input {
        border-color: $border-color;
        background-color: $gray-300;
        color: $gray-700;

        &::placeholder {
            color: $input-placeholder-color;
        }
    }

    @include onHover($border-color);
}
