.link {
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
    }
}
