@import 'piconetworks/pkg-storybook-styles/scss';

.checkbox {
    position: relative;
    cursor: pointer;
    &.disabled {
        pointer-events: none;
    }
    .input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
        opacity: 0;
    }
    .content {
        cursor: pointer;
        color: $gray-600;
        display: block;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding-left: 30px;
        position: relative;
        text-align: left;
        p {
            font-size: 18px;
            margin: 0;
        }
        &.disabled {
            .checkMark {
                .checkMarkOuter {
                    background-color: $gray-400;
                    pointer-events: none;
                    &.selected {
                        background-color: $component-active-disabled-bg;
                    }
                }
            }
        }
        .checkMark {
            left: 0;
            position: absolute;
            &:hover {
                .checkMarkOuter {
                    border-color: $gray-700;
                }
            }
        }
    }
}

.checkBoxText {
  .icon {
      margin-right: 0.5rem;
  }
  .title {
      color: $gray-900;
      display: inline-block;
      font-size: $font-size-sm;
      font-weight: 400;
      line-height: 18px;
  }
  .description {
      font-size: $font-size-sm;
      font-weight: 400;
      line-height: 2;
  }
}

.checkMarkOuter,
.checkMarkInner {
    box-sizing: border-box;
    position: absolute;
}

.checkMarkOuter {
    background: $white;
    box-sizing: border-box;
    border: $border-width solid $gray-500;
    border-radius: 4px;
    border-width: 2px;
    height: 18px;
    left: 0;
    width: 18px;
    &:hover {
        border-color: $gray-700;
    }
    &.selected {
        background-color: var(--linkColor, #834eff);
        border-color: transparent;
        border-width: 0px;
    }
}
.checkMarkInner {
    height: 16px;
    width: 16px;
    left: 1px;
    top: 1px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    .indeterminate {
        height: 2px;
        width: 6px;
        border-radius: 1px;
        background-color: $white;
        position: absolute;
    }
    svg {
      width: 0.9rem;
    }
}
