@import 'piconetworks/pkg-storybook-styles/scss';

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 6px;
    color: $gray-800;
    &:before {
        content: ' ';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $gray-400;
        margin-right: 8px;
    }
    &.met {
        &:before {
            background-color: $success;
        }
    }
}
