@import 'piconetworks/pkg-picore-styles/scss';

.menu {
  position: relative;
}

.menuContainer {
  border-radius: 0.5rem;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  display: none;
  right: 1rem;
  overflow: hidden;
  position: absolute;
  top: 54px;
  width: calc(100% - 2rem);
  @include box-shadow(
    0 1px 6px rgba(0, 0, 0, 0.06),
    0 2px 32px rgba(0, 0, 0, 0.16)
  );

  @include media-breakpoint-up(md) {
    max-height: 0;
    right: 0;
    top: 38px;
  }
}

.isOpen {
  display: block;
  max-height: 100rem;
  max-width: 360px;
}

.mobile {
  background-color: #f4f4f4;

  .menuContainer {
      bottom: 60px;
      left: 16px;
      top: auto;
      width: 360px;
  }
}
