@import 'piconetworks/pkg-storybook-styles/scss';

.scrollableWrapper {
    border: $border-width solid $gray-500;
    border-radius: 1rem;
    margin: 2rem 0 0;

    &:first-child {
        margin: 0;
    }
}

.scrollableHeader {
    padding: 1rem;
    border-bottom: $border-width solid $gray-500;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;

    & > *:last-child {
        cursor: pointer;
        color: $purple;
    }

    & + .scrollableContainer {
        border-radius: 0 0 1rem 1rem;
    }
}

.scrollableContainer {
    background-color: $gray-300;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 12.5rem;
    max-height: 15.55rem;
    padding: 2rem;
    border-radius: 1rem;
    overflow: auto;
}