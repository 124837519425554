@import 'piconetworks/pkg-picore-styles/scss';

.PicoLoaderContainer_modal {
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

@media only screen and (min-width: 768px) {
    .PicoLoaderContainer_modal {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

@media only screen and (max-width: 767px) {
    .PicoLoaderContainer_modal {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}

.PicoLoader_modal {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 109%;

    .topContainer{
        margin: 0px;
        height: 41%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:white;
    }

    .arrowContainer{
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .bottomContainer{
        margin: 0;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 110px;
        line-height: 26px;
        background-color: white;
    }
}
