@import 'piconetworks/pkg-storybook-styles/scss';

.link {
    cursor: pointer;
    transition: $transition-base;
    text-decoration: none;
    &.active {
        color: $link-color;
    }

    &.disabled {
      color: $purple-300;
      pointer-events: none;
      cursor: default;
    }

    &.withIcon {
      display: inline-flex;
      align-items: center;
      svg {
        height: 24px;
        width: 24px;
      }
      .text {
        padding-left: .5rem;
      }
    }
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    &.pillPreset {
      padding: 6px 20px 6px 12px;
      margin: .25rem;
      background-color: $navbar-light-bg-color;
      border-radius: .5rem;
      &:hover {
        background-color: rgba($black, .03);
        opacity: 1;
        text-decoration: none;
      }
      &:active {
        background-color: rgba($black, .07);
        color: $purple-600;
        opacity: 1;
        text-decoration: none;
      }
    }
}
// :global{
//   a, a:not([href]) {
//     &.link-primary {
//       color: $primary;
//     }
//     &.link-success {
//       color: $success;
//     }
//     &.link-info {
//       color: $info;
//     }
//     &.link-warning {
//       color: $warning;
//     }
//     &.link-danger {
//       color: $danger;
//     }
//   }
// }
